import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { SocketModel } from "@/models/SocketModel";
import router from "@/router";
import useEmitter from '@/composables/Emmiter'
import { io } from 'socket.io-client';

const emitter = useEmitter();

@Module
export default class SocketModule extends VuexModule implements SocketModel {
  main:any;  
  isNotificationGerenciar:any;
  session:any = localStorage.getItem("notification")?localStorage.getItem("notification"):"";
  notifications:any =  this.session!=""?JSON.parse(this.session):[];
  /**
   * Get data dashboard
   * @returns a main socket
   */
  get mainSocket(): any {
    return this.main;
  }
  get isNotificationGerenciarSocket(): any {
    return this.isNotificationGerenciar;
  }

  get notificationsSession(): any {
    return this.notifications;
  }

  get notificationCount(): any {
    return this.notifications.length;
  }

  @Mutation
  [Mutations.START_SOCKET]() {
    console.log("iniciou");
    if(!this.main){
      this.main = io("https://node-indicar.azurewebsites.net");
    }
  }
  
  @Action
  [Actions.NEW_LISTEN](options) {
    console.log("chamou action",options);
    this.main.on(options.name, (data) => options.callbackFunction(data));
  }

  @Mutation
  [Mutations.NOTIFICATION_IN_GERENCIAR]() {
    this.isNotificationGerenciar  = this.isNotificationGerenciar ? false:true;
    console.log("action gerenciar notificacao",this.isNotificationGerenciar);
  }

  @Mutation
  [Mutations.SAVE_NEW_NOTIFICATION](notification, codConcessionaria) {
    // if(this.notifications.length==10){//bloqueio para mostrar no maximo 10
    //   this.notifications.shift();
    // }
    this.notifications.push(notification);
    //caso queira ativar para salvar mesmo trocando de concessionaria 
    // var indexStorage = this.notifications.findIndex(x => x.codConcessionaria === codConcessionaria);
    // if(indexStorage>=0){
    //   this.notifications[indexStorage].notification.push(notification);
    // }else{
    // var storageObject =  {
    //   "codConcessionaria" : codConcessionaria,
    //   "notification" : notification
    // }
    // this.notifications.push(storageObject);
    // }

     localStorage.setItem("notification", JSON.stringify(  this.notifications));
    console.log("codConcessionaria, mutations",codConcessionaria, this.notifications);
  }

  @Mutation
  [Mutations.DELETE_ALL_NOTIFICATION]() {
    this.notifications = [];
    localStorage.setItem("notification", "");
    console.log("mutations",this.notifications);
  }

  @Action
  [Actions.ALTER_TITLE]() {
    const distribuidor = this.context.getters.layoutConfig("distribuidor.distribuidor").toLowerCase();
    const titulo = distribuidor[0].toUpperCase() + distribuidor.substr(1) + " - Gestão Oferta Digital";
    if(this.notifications.length>0){
     document.title = "("+  this.notifications.length+") " + titulo;
    }else{
      document.title =  titulo;
    }
  }


  @Action
  [Actions.REMOVE_LISTEN](options) {
    console.log("removeu action",options);
    this.main.removeListener(options.name);
  }

  @Action
  [Actions.DISCONNECT_SOCKET]() {
    if(this.main) {
      this.main.disconnect();
    }
  }
}