
import { ElNotification } from 'element-plus';
import { defineComponent, onMounted,onUnmounted, computed, ref, watch} from "vue";
import store from "@/store/index";

import { sendNotificacao, setToken } from "@/services/GeralService";

import {getAuth, signInAnonymously, signOut} from 'firebase/auth'
import {getMessaging, onMessage, getToken} from 'firebase/messaging'
import {messaging} from '@/FirebaseInit'

import useAlert from '@/composables/Alert.ts';
import {useRouter, useRoute} from 'vue-router';
import Swal from "sweetalert2/dist/sweetalert2.js";
import useEmitter from '@/composables/Emmiter'
import { useStore } from 'vuex';
import { Actions, Mutations } from "@/store/enums/StoreEnums";

//import '@/assets/sass/distribuidor/atria.scss';
export default defineComponent({
  name: "app",

  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const favicon:any = document.getElementById("favicon");
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    const titulo = distribuidor[0].toUpperCase() + distribuidor.substr(1) + " - Gestão Oferta Digital";
    favicon.href = "./distribuidor/favicon_"+distribuidor+"/favicon.ico";
    let localContadorNotificacao:any = localStorage.getItem("localContadorNotificacao")?localStorage.getItem("localContadorNotificacao"):0;
    document.title = titulo;
    const emitter = useEmitter();

    let isActiveAba = ref(true);
    let page = ref(0);
    let postData = {
                    "token": ""
                    };
    let notificacaoHabilitada = true;




    watch(() => store.getters.isUsuarioLogado, async () => {
          if(store.getters.isUsuarioLogado){
             store.commit(Mutations.START_SOCKET);
            //registraServiceWorker();
            //iniciaNotificacao();
           }else{                    
              store.dispatch(Actions.DISCONNECT_SOCKET);         
           }
      
    });

    function goTo(){       
                localContadorNotificacao = 0; 
                localStorage.setItem("localContadorNotificacao","0");
                router.push("/oficina-digital/gerenciar");
    }

    function showNotificationAlert(title, cliente, placa){	

        var audio = new Audio('./sound/sound.mp3');
        audio.play();

        if(route.name!="gerenciar-oficina-digital"){
              localContadorNotificacao++;
              document.title = "("+localContadorNotificacao+") " + titulo;
              localStorage.setItem("localContadorNotificacao",localContadorNotificacao);
        }

          ElNotification({
            title: title,
            message: cliente + "<br>" + placa,
            dangerouslyUseHTMLString: true,
            type: 'info',
            duration: 30000,
            customClass: "notificacaoToast",
            onClick: function goTo(){       
                router.push("/oficina-digital/gerenciar");
            }
            });
            
        

        /* Swal.fire({
        title: title,
        icon: 'info',
        position: 'top-end',
        confirmButtonText: "ir",
        iconColor: '#fff',
        customClass: {      
          popup: 'popupNotification d-flex justify-content-between',
          title: 'ps-5 text-white',
          icon: 'ms-2 iconNotification'
        },
        toast: true,
        timer: 20000,
        allowOutsideClick: () => !Swal.isLoading()
          }).then((result) =>{
                  if(result.isConfirmed){          
                      // console.log("clicked result is confirmed");
                      goTo();
                  }
            });
     */
    }

    // async function iniciaNotificacao(){
      
    //   await onMessage(messaging, (payload) =>{
    //     if(payload['data']){
    //        showNotificationAlert(payload['data']['title'],payload['data']['cliente'],payload['data']['placa']);
    //       // console.log("message on client : ",payload['notification']['body']);
    //     }
    //   });

    // }

    // function desmontar(){
    //   let contagem:any;
    //   if(localStorage.getItem("contagem")&&localStorage.getItem("contagem")!=null){
    //     const localContagem:any = localStorage.getItem("contagem");
    //     contagem = JSON.parse(localContagem);      
    //   }
    //   var myIndex = contagem.indexOf(page.value);
    //   alert("aba desmontar page"+ page.value);
    //    alert("aba desmontar myIndex"+ myIndex);
    //   if(myIndex !== -1){
    //     contagem.splice(myIndex, 1);
    //   }
    //   localStorage.setItem("contagem", JSON.stringify(contagem));
    //   alert("aba desmontar"+ contagem);
    // }

    // async function registraServiceWorker(){
    //     // if (!("Notification" in window)) {
    //     //   notificacaoHabilitada = false;
    //     // /*  return 0; */
    //     // } else if (Notification.permission === "default") {
    //     //   Notification.requestPermission();
    //     // }
    //     // if(Notification.permission !== "granted"){
    //     //   notificacaoHabilitada = false;
    //     // /*  return 0; */
    //     // }
    //     // if(!("serviceWorker" in navigator)){
    //     //   notificacaoHabilitada = false;
    //     // /*  return 0; */
    //     // }

    //     // if (notificacaoHabilitada) {
    //     //     navigator.serviceWorker
    //     //       .register("./firebase-messaging-sw.js")
    //     //       .then( async function(registration) {
    //     //         // console.log("Registration successful, scope is:", registration.scope);
    //     //       await getToken(messaging, {
    //     //       vapidKey : 'BE6lNze8J24nGu2IRW9YCZiSaXIOnVoGhAMGSRcTiBlfenq4QtkkVJarpejEDXaQjj9b9t8k7HtWl8HUmzmgzBg', 
    //     //       serviceWorkerRegistration : registration })
    //     //           .then(async (currentToken) => {
    //     //             if (currentToken) {
    //     //               // console.log('current token for client: ', currentToken);
    //     //               postData = {
    //     //                 "token": currentToken
    //     //               };  
    //     //               await setToken(postData)
    //     //                 .then((value)=>{
    //     //                 // console.log("token: ", value);
    //     //                 })
    //     //                 .catch((error)=>{
    //     //                   // console.log(error);
    //     //               }); 
    //     //               // Track the token -> client mapping, by sending to backend server
    //     //               // show on the UI that permission is secured
    //     //             } else {
    //     //               console.log('No registration token available. Request permission to generate one.');

    //     //               // shows on the UI that permission is required 
    //     //             }
    //     //           }).catch((err) => {
    //     //             console.log('An error occurred while retrieving token. ', err);
    //     //             // catch error while creating client token
    //     //           }); 
    //     //           //registra token no backend
                  
    //     //         })
    //     //         .catch(function(err) {
    //     //           console.log("Service worker registration failed, error:"  , err );
    //     //       }); 
    //     //     }
    //     //   else {
    //     //       await setToken(postData)
    //     //         .then((value)=>{
    //     //         // console.log("token: ", value);
    //     //         })
    //     //         .catch((error)=>{
    //     //         // console.log(error);
    //     //       });

    //     //   } 
            

        
    // };

    onMounted(async ()=>{
        if(store.getters.isUsuarioLogado){
              store.commit(Mutations.START_SOCKET);

              //registraServiceWorker();              
              //verificaAtualizacoes();
              //iniciaNotificacao();
        }


        // let contagem:any;
        // if(localStorage.getItem("contagem")&&localStorage.getItem("contagem")!=null){
        //   const localContagem:any = localStorage.getItem("contagem");
        //   contagem = JSON.parse(localContagem);      
        //   if(contagem.length-1>=0){
        //     if(Number(contagem.length)>=10){
        //       contagem.splice(0, contagem.length);
        //     }else{
        //       page.value = Number(contagem[contagem.length-1])+1;
        //     }
        //   }
        // }else{
        //     contagem = [];
        //   }
    
        // contagem.push(page.value);
        // localStorage.setItem("contagem", JSON.stringify(contagem));

        // window.addEventListener("storage", storageChanged, false);

        // function storageChanged(event) {
        //   // console.log("alteracao storage", event);
        //   const localContagem:any = event.newValue;
        //   contagem = JSON.parse(localContagem);      
        //       // console.log("aba verifica igualdade contagem[contagem.length-1]", contagem[contagem.length-1]);
        //       // console.log("aba verifica igualdade page", page.value);
        //     if ( contagem[contagem.length-1] == page.value ){  
        //         isActiveAba.value = true;
        //         // console.log("aba ativa", contagem);
        //     }else{                                              
        //         isActiveAba.value = false;
        //         // console.log("aba pagina desativada", contagem);
        //     }
        // }


      // window.onbeforeunload = function(){
      //   //desmontar();
      // }
    });

    onUnmounted(()=>{
      store.dispatch(Actions.DISCONNECT_SOCKET);
    })

    let dataNotificacao = "";
    let interval;


    // store.watch(() => store.getters.concessionariaSelectedInfo, async () => {
    //   verificaAtualizacoes();
    // }, { deep: true });

    function verificaAtualizacoes(){
      if(store.getters.concessionariaSelectedInfo.codConcessionaria){
      const codConcessionaria = store.getters.concessionariaSelectedInfo.codConcessionaria;
        
        // console.log("verifica atualizacoes...", codConcessionaria);

         if(!isActiveAba.value){
          dataNotificacao = "";
        }

        if (store.getters.isUsuarioLogado && isActiveAba.value) {
          const response = sendNotificacao(codConcessionaria, postData, dataNotificacao)
            .then((value)=>{
              dataNotificacao = value["data"];
              if(value["body"]){
                  emitter.emit("atualiza-gerenciar");
                  showNotificationAlert(value['title'],value['cliente'],value["placa"]);
              }
            }).catch((error)=>{
              console.log(error);
          });	
        }
        
        if(interval){
          clearInterval(interval);
        }

        interval = setInterval(() => { 
          if(!isActiveAba.value){
            dataNotificacao = "";
          }
          if (store.getters.isUsuarioLogado && isActiveAba.value) {

            const response = sendNotificacao(codConcessionaria, postData, dataNotificacao)
              .then((value)=>{
              dataNotificacao = value["data"];
              if(value["body"]){
                  emitter.emit("atualiza-gerenciar");
                  // console.log("entrou no if do setInterval");
                   showNotificationAlert(value['title'],value['cliente'], value["placa"] );
              }
              })
              .catch((error)=>{
                console.log(error);
            });	
          }
        }, 120000);

      }
    }

  
  },
});
