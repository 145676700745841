const distribuidorConfig : Array<any> = [	
  {
	  "codDistribuidor" : 12,
	  "distribuidor" : "stellantis",
	  "dominio_api" : process.env.VUE_APP_URL_API,
	  "dominio_checklist" : process.env.VUE_APP_URL_CHECKLIST,
	  "name_empresa" : "Stellantis",
	  "link_loja_ios" : "https://apps.apple.com/br/app/stellantis-oferta",
	  "link_loja_android" : "https://play.google.com/store/apps/details?id=app.stellantis.oferta",
	  "mascara_codigo_interno" : "00000",
	  "mascara_codigo_login" : "###.##",
	  "integracao_dms" : "https://dms.camptecnologia.com.br/",
	  "token_integracao_dms" : "f08Ae54b97c066d0ecSD256f97c0608e54b9ea26d0e54a7RRea2e135",
	  "tipo_conexao" : "https"
  }
];
export default distribuidorConfig;
