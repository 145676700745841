import { UsuarioModel } from "@/models/UsuarioModel";

const ID_USUARIO_KEY = "id_usuario" as string;

/**
 * @description get usuario form localStorage
 */
export const getUsuarioAuth = (): UsuarioModel => {

  if(window.localStorage.getItem(ID_USUARIO_KEY))
  {
    return JSON.parse(window.localStorage.getItem(ID_USUARIO_KEY) || '{}') as UsuarioModel;
  }
  else
  {
    return JSON.parse(window.sessionStorage.getItem(ID_USUARIO_KEY) || '{}') as UsuarioModel;
  }

 };

/**
 * @description save usuario into localStorage
 * @param usuario: UsuarioModel
 */
export const saveUsuarioAuth = (usuario: UsuarioModel, local: number): void => {
  if(local == 1)
  {
    window.localStorage.setItem(ID_USUARIO_KEY, JSON.stringify(usuario));
  }
  else
  {
    window.sessionStorage.setItem(ID_USUARIO_KEY, JSON.stringify(usuario));
  }
  
};

/**
 * @description remove usuario form localStorage
 */
export const destroyUsuarioAuth = (): void => {
  window.localStorage.removeItem(ID_USUARIO_KEY);
  window.sessionStorage.removeItem(ID_USUARIO_KEY);
};

export default { getUsuarioAuth, saveUsuarioAuth, destroyUsuarioAuth };
