import { TokenModel } from "@/models/TokenModel";

const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {

  if(window.localStorage.getItem(ID_TOKEN_KEY))
  {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  }
  else
  {
    return window.sessionStorage.getItem(ID_TOKEN_KEY);
  }
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string, local: number): void => {
  if(local == 1)
  {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  }
  else
  {
    window.sessionStorage.setItem(ID_TOKEN_KEY, token);
  }
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.sessionStorage.removeItem(ID_TOKEN_KEY);
};

export const decodeToken = (token): TokenModel => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload) as TokenModel;
};

export default { getToken, saveToken, destroyToken, decodeToken };
