import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import FilterModule from "@/store/modules/FilterModule"; 
import SocketModule from "@/store/modules/SocketModule"; 
import AgendaModule from "@/store/modules/AgendaModule"; 

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    FilterModule,
    SocketModule,
    AgendaModule
  },
});

export default store;
