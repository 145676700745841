const nivelRota = {
  '-1': 'dashboard',
  '1': 'dashboard',
  '2': 'dashboard',
  '3': 'gerenciar-oficina-digital',
  // '3': 'prod-gerenciar-oficina-digital',
  '4': 'agenda-virtual-agendar',
  '5': 'agenda-virtual-exibir',
  '6': 'historico-checklist',
}

const nivelRotaStellantis = {
  '-1': 'cadastros-concessionaria-list',
  '1': 'historico-checklist-stellantis',
  '2': 'cadastros-concessionaria-list',
  '3': 'historico-checklist-stellantis ',
  '6': 'historico-checklist-stellantis',
}

export {nivelRota,nivelRotaStellantis};
